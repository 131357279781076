import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { HiOutlinePlus } from "react-icons/hi";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { RiDeleteBinLine } from "react-icons/ri";

function TeamsEdit({ show, onHide, team }) {
    const [picture, setPicture] = useState("");
    const [trainings, setTrainings] = useState([]);
    const [about, setAbout] = useState("");
    const [slogan, setSlogan] = useState("");
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState("");
    const [showTraining, setShowTraining] = useState(false);
    const [newSeason, setNewSeason] = useState(true);
    const [newTime, setNewTime] = useState("15:00");
    const [newLocation, setNewLocation] = useState("");
    const [newDuration, setNewDuration] = useState(1);
    const [newDay, setNewDay] = useState("Montag");
    const [training, setTraining] = useState({});

    useEffect(() => {
        if (team) {
            setPicture(team.picture);
            setAbout(team.about);
            setTrainings(team.trainings);
            setSlogan(team.slogan);
            setPreview("https://server.raketefreiburg.de/uploads/" + team.picture)
        }
    }, [team]);

    const deleteTraining = (teamId, trainingsId) => {
        setLoading(true);

        axios
            .delete(`/teams/${teamId}/trainings/${trainingsId}`)
            .then(() => {
                setLoading(false);
                toast.success("Trainingszeit gelöscht");
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    function handleChange(event) {
        setPicture(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
    }

    const addTraining = () => {
        setShowTraining(prevShowTraining => !prevShowTraining);
    };

    const closeTeamEdit = () => {
        onHide();
    }

    if (!team) {
        return null
    }
    const handleEditTeams = (id) => {
        const formData = new FormData();
        formData.append('picture', picture);
        formData.append("about", about);
        formData.append("slogan", slogan);

        setLoading(true);

        axios
            .put(`/api/Teams/${id}`, formData)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    const handleAddTraining = (id) => {
        const formData = {
            day: newDay,
            time: newTime,
            location: newLocation,
            season: newSeason,
            duration: newDuration
        }

        setLoading(true);

        axios
            .post(`/teams/${id}/trainings`, formData)
            .then(() => {
                setLoading(false);
                toast.success("Trainingszeit hinzugefügt");
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Fehler beim Hinzufügen der Trainingszeiten");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeTeamEdit} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeTeamEdit}>{team.name}</Modal.Header>
                <Modal.Body>
                    <img src={preview} className="img-fluid team-preview rounded shadow-sm" />
                    <div id="fileUpload" className="">
                        <Form.Label value="Bild" className="rakete-font text-uppercase fs-8 rakete-rot">Bild</Form.Label>
                        <Form.Control className="form-control-sm" id="file" type="file" onChange={handleChange} accept="image/*" />
                    </div>
                    <div className='py-2'>
                        <div value="training" className="rakete-font text-uppercase fs-8 rakete-rot">Training</div>
                        {!team.trainings || team.trainings.length === 0 ? (
                            <p className='text-center fs-7'>Keine Trainingszeiten vorhanden</p>
                        ) : (
                            <div className='py-2'>
                                <div className='text-center fw-semibold rakete-font mb-2 fs-7' colspan="5">Sommer</div>
                                <table className="table table-striped">
                                    <thead className='text-center fs-8 rakete-font'>
                                        <th>Tag</th>
                                        <th>Uhrzeit</th>
                                        <th>Dauer</th>
                                        <th>Ort</th>
                                    </thead>
                                    <tbody className='text-center'>
                                        {team.trainings
                                            .filter(training => training.season)
                                            .sort((a, b) => {
                                                const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                            })
                                            .map((training) => (
                                                <tr className="table fs-8">
                                                    <th>{training.day}</th>
                                                    <td>{training.time} Uhr</td>
                                                    <td>{training.duration} Std</td>
                                                    <td>{training.location}</td>
                                                    <td><RiDeleteBinLine size={15} className={`icons mx-3 my-1 hover`} onClick={() => { deleteTraining(team._id, training._id) }} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                <div className='text-center fw-semibold rakete-font mb-2 fs-7' colspan="5">Winter</div>
                                <table className="table table-striped">
                                    <thead className='text-center fs-8 rakete-font text-uppercase'>
                                        <th>Tag</th>
                                        <th>Uhrzeit</th>
                                        <th>Dauer</th>
                                        <th>Ort</th>
                                    </thead>
                                    <tbody className='text-center'>
                                        {team.trainings
                                            .filter(training => !training.season)
                                            .sort((a, b) => {
                                                const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                            })
                                            .map((training) => (
                                                <tr className="table fs-8">
                                                    <th>{training.day}</th>
                                                    <td>{training.time} Uhr</td>
                                                    <td>{training.duration} Std</td>
                                                    <td>{training.location}</td>
                                                    <td><RiDeleteBinLine size={15} className={`icons mx-3 my-1 hover`} onClick={() => { deleteTraining(team._id, training._id) }} /></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {showTraining ? (
                            <div>
                                <p className='rakete-font text-uppercase fs-6 rakete-rot text-center mt-1'>Neues Training</p>
                                <div>
                                    <Form.Label value="Season" className="rakete-font text-uppercase fs-8">Saison</Form.Label>
                                    <Form.Select aria-label="Default select example" size="sm" onChange={(e) => setNewSeason(e.target.value)}>
                                        <option value="true">Sommer</option>
                                        <option value="false">Winter</option>
                                    </Form.Select>
                                </div>
                                <Row>
                                    <Form.Group className="my-2" as={Col}>
                                        <Form.Label value="Day" className="rakete-font text-uppercase fs-8">Tag</Form.Label>
                                        <Form.Select aria-label="Default select example" size="sm" onChange={(e) => setNewDay(e.target.value)}>
                                            <option value="Montag">Montag</option>
                                            <option value="Dienstag">Dienstag</option>
                                            <option value="Mittwoch">Mittwoch</option>
                                            <option value="Donnerstag">Donnerstag</option>
                                            <option value="Freitag">Freitag</option>
                                            <option value="Samstag">Samstag</option>
                                            <option value="Sonntag">Sonntag</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="my-2" as={Col}>
                                        <Form.Label value="Time" className="rakete-font text-uppercase fs-8">Start</Form.Label>
                                        <Form.Select aria-label="Default select example" size="sm" onChange={(e) => setNewTime(e.target.value)}>
                                            <option value="15:00">15:00</option>
                                            <option value="15:30">15:30</option>
                                            <option value="16:00">16:00</option>
                                            <option value="16:30">16:30</option>
                                            <option value="17:00">17:00</option>
                                            <option value="17:30">17:30</option>
                                            <option value="18:00">18:00</option>
                                            <option value="18:30">18:30</option>
                                            <option value="19:00">19:00</option>
                                            <option value="19:30">19:30</option>
                                            <option value="20:00">20:00</option>
                                            <option value="20:30">20:30</option>
                                            <option value="21:00">21:00</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="my-2" as={Col}>
                                        <Form.Label value="Duration" className="rakete-font text-uppercase fs-8">Dauer in Std</Form.Label>
                                        <Form.Select aria-label="Default select example" size="sm" onChange={(e) => setNewDuration(e.target.value)}>
                                            <option value="1">1</option>
                                            <option value="1.5">1.5</option>
                                            <option value="2">2</option>
                                            <option value="2.5">2.5</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <div>

                                </div>
                                <div>
                                    <Form.Label value="Location" className="rakete-font text-uppercase fs-8">Ort</Form.Label>
                                    <Form.Control
                                        id="Location"
                                        type="text"
                                        className='form-control-sm'
                                        value={newLocation}
                                        onChange={(e) => setNewLocation(e.target.value)}
                                    />
                                </div>
                                <div>

                                </div>
                                <div>

                                </div>
                                <div className='text-center'>
                                    <button className="text-center secondary-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1 my-2" onClick={() => handleAddTraining(team._id)} type="submit">Hinzufügen</button>
                                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7 mx-1 my-2" onClick={addTraining}>Schließen</button>
                                </div>
                            </div>
                        ) : (
                            <div className='text-center'>
                                <HiOutlinePlus size={22} className='mx-1 hover icon-edit my-1' onClick={addTraining} />
                            </div>)}
                    </div>
                    <div>
                        <Form.Label value="Slogan" className="rakete-font text-uppercase fs-8 rakete-rot">Slogan</Form.Label>
                        <Form.Control
                            id="Slogan"
                            type="text"
                            className='form-control-sm'
                            value={slogan}
                            onChange={(e) => setSlogan(e.target.value)}
                        />
                    </div>
                    <div className="">
                        <Form.Label value="About Us" className="rakete-font text-uppercase fs-8 rakete-rot">About us</Form.Label>
                        <Form.Control
                            as="textarea"
                            id="about"
                            className='form-control-sm'
                            placeholder="Rakete ist geil..."
                            rows={4}
                            value={about}
                            onChange={(e) => setAbout(e.target.value)}
                        />
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={() => handleEditTeams(team._id)} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeTeamEdit}>
                        Verwerfen
                    </button>

                </Modal.Footer>
            </Modal >
        </>

    );
}

export default TeamsEdit;