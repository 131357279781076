import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik } from 'formik';
import * as Yup from 'yup'; // Yup zur Validierung importieren

function MemberAdd({ show, onHide, setMembers }) {
    const [loading, setLoading] = useState(false);

    const closeMemberAdd = () => {
        onHide();
    };

    // Yup-Validierungsschema
    const validationSchema = Yup.object().shape({
        forename: Yup.string().required('Vorname ist erforderlich'),
        lastname: Yup.string().required('Nachname ist erforderlich'),
        email: Yup.string().email('Ungültige E-Mail-Adresse').required('E-Mail ist erforderlich'),
        phoneNumber: Yup.string().required('Telefonnummer ist erforderlich'),
        street: Yup.string().required('Straße ist erforderlich'),
        houseNumber: Yup.string().required('Hausnummer ist erforderlich'),
        city: Yup.string().required('Stadt ist erforderlich'),
        postalCode: Yup.string().required('Postleitzahl ist erforderlich'),
        birthday: Yup.date().required('Geburtsdatum ist erforderlich'),
        entryDate: Yup.date().required('Eintrittsdatum ist erforderlich'),
        role: Yup.string().required('Rolle ist erforderlich'),
        gender: Yup.string().required('Geschlecht ist erforderlich'),
    });

    const handleAddMember = (values) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            if (key === 'memberFile' && values.memberFile) {
                formData.append(key, values[key]); // Datei hinzufügen
            } else {
                formData.append(key, values[key]); // Andere Felder hinzufügen
            }
        });

        setLoading(true);

        axios.post("/members/save", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(() => {
                setLoading(false);
                onHide();
                setMembers(prevMembers => [...prevMembers, values]);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error:", error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeMemberAdd} size="md" className='mx-auto'>
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeMemberAdd}>
                    Mitglied hinzufügen
                </Modal.Header>
                <Modal.Body className='fs-7'>
                    <Formik
                        initialValues={{
                            forename: '',
                            lastname: '',
                            email: '',
                            phoneNumber: '',
                            street: '',
                            houseNumber: '',
                            city: '',
                            postalCode: '',
                            birthday: '',
                            entryDate: '',
                            role: '',
                            gender: '',
                            user: false,
                            active: true,
                            special: false,
                            team: '',
                            documentsAvailable: false,
                            memberFile: null
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleAddMember}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="my-2">
                                    <Form.Label>Geschlecht</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        className='form-control-sm'
                                        name="gender"
                                        value={values.gender}
                                        onChange={handleChange}
                                        isInvalid={touched.gender && !!errors.gender}
                                    >
                                        <option value="">Geschlecht wählen</option>
                                        <option value="0">Mann</option>
                                        <option value="1">Frau</option>
                                        <option value="2">Divers</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.gender}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row className="my-2">
                                    <Form.Group as={Col}>
                                        <Form.Label>Vorname</Form.Label>
                                        <Form.Control
                                            id="forename"
                                            type="text"
                                            className='form-control-sm'
                                            name="forename"
                                            value={values.forename}
                                            onChange={handleChange}
                                            isInvalid={touched.forename && !!errors.forename}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.forename}
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                    <Form.Group as={Col}>
                                        <Form.Label>Nachname</Form.Label>
                                        <Form.Control
                                            id="lastname"
                                            type="text"
                                            className='form-control-sm'
                                            name="lastname"
                                            value={values.lastname}
                                            onChange={handleChange}
                                            isInvalid={touched.lastname && !!errors.lastname}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.lastname}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Form.Group className="my-2">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        id="email"
                                        type="email"
                                        className='form-control-sm'
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Label>Telefonnummer</Form.Label>
                                    <Form.Control
                                        id="phoneNumber"
                                        type="text"
                                        className='form-control-sm'
                                        name="phoneNumber"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phoneNumber}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row className="my-2">
                                    <Form.Group as={Col}>
                                        <Form.Label>Straße</Form.Label>
                                        <Form.Control
                                            id="street"
                                            type="text"
                                            className='form-control-sm'
                                            name="street"
                                            value={values.street}
                                            onChange={handleChange}
                                            isInvalid={touched.street && !!errors.street}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.street}
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                    <Form.Group as={Col} xs={3}>
                                        <Form.Label>Nr.</Form.Label>
                                        <Form.Control
                                            id="houseNumber"
                                            type="text"
                                            className='form-control-sm'
                                            name="houseNumber"
                                            value={values.houseNumber}
                                            onChange={handleChange}
                                            isInvalid={touched.houseNumber && !!errors.houseNumber}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.houseNumber}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="my-2">
                                    <Form.Group as={Col} xs={5}>
                                        <Form.Label>Postleitzahl</Form.Label>
                                        <Form.Control
                                            id="postalCode"
                                            type="text"
                                            className='form-control-sm'
                                            name="postalCode"
                                            value={values.postalCode}
                                            onChange={handleChange}
                                            isInvalid={touched.postalCode && !!errors.postalCode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.postalCode}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Stadt</Form.Label>
                                        <Form.Control
                                            id="city"
                                            type="text"
                                            className='form-control-sm'
                                            name="city"
                                            value={values.city}
                                            onChange={handleChange}
                                            isInvalid={touched.city && !!errors.city}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.city}
                                        </Form.Control.Feedback>
                                    </Form.Group >
                                </Row>

                                <Form.Group className="my-2">
                                    <Form.Label>Geburtstag</Form.Label>
                                    <Form.Control
                                        id="birthday"
                                        type="date"
                                        className='form-control-sm'
                                        name="birthday"
                                        value={values.birthday}
                                        onChange={handleChange}
                                        isInvalid={touched.birthday && !!errors.birthday}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.birthday}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Label>Team</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        className='form-control-sm'
                                        name="team"
                                        value={values.team}
                                        onChange={handleChange}
                                    >
                                        <option value="">Team wählen</option>
                                        <option value="0">Erste</option>
                                        <option value="1">Zweite</option>
                                        <option value="2">Mixed</option>
                                        <option value="3">Frauen</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Label>Eintrittsdatum</Form.Label>
                                    <Form.Control
                                        id="entryDate"
                                        type="date"
                                        className='form-control-sm'
                                        name="entryDate"
                                        value={values.entryDate}
                                        onChange={handleChange}
                                        isInvalid={touched.entryDate && !!errors.entryDate}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.entryDate}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row className="my-2">
                                    <Form.Group className="my-2 role" as={Col}>
                                        <Form.Label>Rechte</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            name="rights"
                                            value={values.rights}
                                            onChange={handleChange}
                                        >
                                            <option value="0">Keine</option>
                                            <option value="1">Operator</option>
                                            <option value="2">Admin</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="my-2 role" as={Col}>
                                        <Form.Label>Rolle</Form.Label>
                                        <Form.Select
                                            size="sm"
                                            name="role"
                                            value={values.role}
                                            onChange={handleChange}
                                            isInvalid={touched.role && !!errors.role}
                                        >
                                            <option value="">Wähle eine Rolle</option>
                                            <option value="0">Keine</option>
                                            <option value="1">1. Vorstand</option>
                                            <option value="2">2. Vorstand</option>
                                            <option value="3">3. Vorstand</option>
                                            <option value="4">Leitung Erste</option>
                                            <option value="5">Leitung Zweite</option>
                                            <option value="6">Leitung Mixed</option>
                                            <option value="7">Leitung Frauen</option>
                                            <option value="8">Bunte - Liga Koordinator</option>
                                            <option value="9">Turnier - Team</option>
                                            <option value="10">Webmaster</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.role}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Form.Group className="my-2">
                                    <Form.Check
                                        type="switch"
                                        label={values.active ? "Aktiv" : "Passiv"}
                                        name="active"
                                        checked={values.active}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Check
                                        type="switch"
                                        label={values.special ? "Ehren- oder Fördermitglied" : "Kein Ehren- oder Fördermitglied"}
                                        name="special"
                                        checked={values.special}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Check
                                        type="switch"
                                        label={values.user ? "User - Account erstellen" : "Kein User-Account erstellen"}
                                        name="user"
                                        checked={values.user}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group className="my-2">
                                    <Form.Check
                                        type="switch"
                                        label={values.documentsAvailable ? "Antrag bestätigt" : "Antrag fehlt"}
                                        name="documentsAvailable"
                                        checked={values.documentsAvailable}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                {values.documentsAvailable && (
                                    <Form.Group className="my-2">
                                        <Form.Control
                                            id="memberFile"
                                            name="memberFile"
                                            type="file"
                                            accept=".pdf, .jpeg, .jpg, .png"
                                            className='form-control-sm'
                                            onChange={(e) => setFieldValue('memberFile', e.target.files[0])}
                                        />
                                    </Form.Group>
                                )}

                                <Modal.Footer>
                                    <button
                                        className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7"
                                        type="submit"
                                        disabled={loading} // Verhindert das erneute Absenden während des Ladens
                                    >
                                        {loading ? "Speichern..." : "Speichern"}
                                    </button>
                                    <button
                                        className="text-center decline-button text-uppercase rounded fw-semibold fs-7"
                                        onClick={closeMemberAdd}
                                    >
                                        Verwerfen
                                    </button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MemberAdd;
