import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { HiArrowCircleRight } from "react-icons/hi";
import { HiArrowCircleLeft } from "react-icons/hi";


function NewsDetail({ show, onHide, news }) {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [PicturesPerPage, setPicturesPerPage] = useState(3);
  const [selectedPicture, setSelectedPicture] = useState(null);

  const handleResize = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 1000) {
      setPicturesPerPage(1);
      setCurrentIndex(0);
    
    }   
    else {
      setPicturesPerPage(3);

    }
  }, [screenSize]);

  const [loading, setLoading] = useState(false);
  if (!news) {
    return null;
  }



  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    return new Date(date).toLocaleDateString('de-DE', options);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= news.length) {
        return 0;
      } else {
        return newIndex;
      }
    });
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex - 1;
      if (newIndex < 0) {
        newIndex = news.length - 1;
      }
      return newIndex;
    });
  };

  const handleClick = (index) => {
    setSelectedPicture(news.gallery[index]);
  };

  const handleClose = () => {
    setSelectedPicture(0);
    setCurrentIndex(0);
    onHide();

  }


  return (
    <Modal size="lg" show={show} onHide={handleClose} centered className='no-carot'>
      <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase'>{news.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light shadow'>
        <div className='mx-2'>
          <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="img-fluid rounded shadow-sm border" alt={`News: ${news.title}`} />

          <p className="fs-7 fw-semibold text-end mt-2">{formattedDate(news.date)}</p>
          <p className='fw-semibold fs-5 text-center'>{news.subtitle}</p>
          <div dangerouslySetInnerHTML={{ __html: news.content }} />
        </div>
        {news.gallery.length != 0 && (
          <div className="mx-auto mb-5 container-fluid position-relative">
            <h4 className="rakete-font fw-semibold pt-3 ms-4 rakete-rot text-center mb-3">Galerie</h4>
            <div className="row justify-content-center align-items-center">
              {news.gallery.slice(currentIndex, currentIndex + PicturesPerPage).map((picture, index) => (
                <div key={index} className={screenSize >= 1000 ? "col-4" : ""}>
                  <div className={screenSize < 1000 ? "mx-5" : "h-100"}>
                  <img src={`https://server.raketefreiburg.de/uploads/${picture}`} className="card-img-top rounded news-ratio" alt={`News: ${news.title}`} />
                  </div>
                </div>

              ))}

              {currentIndex > 0 && (
                <button className="carousel-control-prev" type="button" onClick={prevImage}>
                  <HiArrowCircleLeft className='rakete-rot fs-1 mt-5' />
                  <span className="visually-hidden">Previous</span>
                </button>
              )}
              {currentIndex + PicturesPerPage < news.gallery.length && (
                <button className="carousel-control-next" type="button" onClick={nextImage}>
                  <HiArrowCircleRight className='rakete-rot fs-1 mt-5' />
                  <span className="visually-hidden">Next</span>
                </button>
              )}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className='border border-0 fs-7'>Verfasser: {news.author}</Modal.Footer>
    </Modal>
  );
}

  export default NewsDetail;