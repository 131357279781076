import React, { useState, useEffect, useRef } from 'react';
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";

function NewsPreview({ newsData, newsIndex, onNewsClick}) {
  const [currentIndex, setCurrentIndex] = useState(newsIndex);
  const [touchStartX, setTouchStartX] = useState(null);
  const touchThreshold = 50;
  const newsPerPage = 1;
  const containerRef = useRef(null);

  const linkStyle = {
    textDecoration: "none",
  };

  useEffect(() => {
    setCurrentIndex(newsIndex);
  }, [newsIndex]);

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchDifference = touchStartX - touchEndX;

    if (touchDifference > touchThreshold) {
      nextNews();
    } else if (touchDifference < -touchThreshold) {
      prevNews();
    }
  };

  const nextNews = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex >= newsData.length) {
        return newsData.length -1;
      } else {
        return newIndex;
      }
    });
  };

  const prevNews = () => {
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex - 1;
      if (newIndex < 0) {
        return 0;
      }
      return newIndex;
    });
  };

  return (
    <div 
      className='no-text-cursor'
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      ref={containerRef}
    >
      <div className=" mb-5 container-fluid mt-3 py-3 position-relative rounded-4 bg-white shadow-lg">
        <p className="rakete-font fw-semibold ms-4 fs-3 text-danger text-uppercase">Preview</p>
        <div className="row justify-content-center pt-3">
          {newsData.slice(currentIndex, currentIndex + newsPerPage).map((news, index) => (
            <div key={index} className="col-8 mb-5" onClick={() => onNewsClick(news._id)}>
              <div className="card news-card h-100 shadow hover">
                <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="card-img-top news-ratio" alt={`News: ${news.title}`} />
                <div className="card-body">
                  <h5 className="text-center fs-6 text-uppercase">{news.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        {currentIndex > 0 && (
          <button className="carousel-control-prev" type="button" onClick={prevNews}>
            <HiArrowCircleLeft className='rakete-rot fs-1' />
            <span className="visually-hidden">Previous</span>
          </button>
        )}
        {currentIndex + newsPerPage < newsData.length && (
          <button className="carousel-control-next" type="button" onClick={nextNews}>
            <HiArrowCircleRight className='rakete-rot fs-1' />
            <span className="visually-hidden">Next</span>
          </button>
        )}
      </div>
    </div>
  );
}

export default NewsPreview;
