import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function PartificantAdd({ show, onHide, tournamentid, refresh }) {
    const [teamname, setTeamname] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthdate, setBirthdate] = useState(new Date());
    const [street, setStreet] = useState('');
    const [zip, setZip] = useState("");
    const [town, setTown] = useState('');
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [tel, setTel] = useState('');
    const [confirmRules, setConfirmRules] = useState(false)
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);

    const closePartificantAdd = () => {
        onHide();
    }

    const handleAddPartificant = () => {
        const formData = {
            teamname,
            name,
            lastname,
            birthdate,
            street,
            zip,
            town,
            email1,
            email2,
            tel,
            confirmRules,
            status
        }

        setLoading(true);

        axios
            .post(`/tournament/${tournamentid}/participants`, formData)
            .then(() => {
                setLoading(false);
                onHide();
                refresh();
                
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closePartificantAdd} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closePartificantAdd}>Teilnehmer hinzufügen</Modal.Header>
                <Modal.Body className='fs-7'>
                    <Form.Group className="my-2">
                        <Form.Label value="teamname" >Mannschaftsname</Form.Label>
                        <Form.Control
                            id="teamname"
                            type="text"
                            className='form-control-sm'
                            value={teamname}
                            onChange={(e) => setTeamname(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="my-2">
                        <Form.Group as={Col}>
                            <Form.Label value="Vorname">Vorname</Form.Label>
                            <Form.Control
                                id="name"
                                type="text"
                                className='form-control-sm'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <Form.Label value="Nachname" >Nachname</Form.Label>
                            <Form.Control
                                id="lastname"
                                type="text"
                                className='form-control-sm'
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group>
                        <Form.Label value="Geburtstag" >Geburtstag</Form.Label>
                        <Form.Control
                            id="birthday"
                            type="date"
                            className='form-control-sm'
                            value={birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label value="street" >Straße</Form.Label>
                        <Form.Control
                            id="street"
                            type="text"
                            className='form-control-sm'
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="my-2">
                        <Form.Group as={Col}>
                            <Form.Label value="plz">PLZ</Form.Label>
                            <Form.Control
                                id="plz"
                                type="text"
                                className='form-control-sm'
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <Form.Label value="town" >Ort</Form.Label>
                            <Form.Control
                                id="town"
                                type="text"
                                className='form-control-sm'
                                value={town}
                                onChange={(e) => setTown(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="my-2">
                        <Form.Label value="Email1" >Email 1</Form.Label>
                        <Form.Control
                            id="email1"
                            type="email"
                            className='form-control-sm'
                            value={email1}
                            onChange={(e) => setEmail1(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label value="Email2" >Email 2</Form.Label>
                        <Form.Control
                            id="email2"
                            type="email"
                            className='form-control-sm'
                            value={email2}
                            onChange={(e) => setEmail2(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label value="tel" >Telefonnummer</Form.Label>
                        <Form.Control
                            id="tel"
                            type="text"
                            className='form-control-sm'
                            value={tel}
                            onChange={(e) => setTel(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-4 mx-3 fw-semibold">
                        <Form.Check
                        required
                            type="checkbox"
                            id="confirmRules"
                            value={confirmRules}
                            label="Team hat den Regeln und den Datenschutzbestimmungen zugestimmt"
                            onChange={(e) => setConfirmRules(e.target.checked)}
                        />
                    </Form.Group>
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddPartificant} type="submit">Hinzufügen</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closePartificantAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>

    );
};

export default PartificantAdd;