import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoClose, IoSettingsOutline } from "react-icons/io5";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import HistoryAdd from './HistoryAdd';
import HistoryDelete from './HistoryDelete';
import dayjs from 'dayjs';

function History() {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [add, setAdd] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [year, setYear] = useState("");
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");


    useEffect(() => {
        setLoading(true);
        axios.get(`/api/History`)
            .then((response) => {
                setHistory(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const handleAdd = (refresh) => {
        setAdd(false);
        setShowDelete(false);
        if (refresh === true) {
            setLoading(true);
            axios.get(`/api/History`)
                .then((response) => {
                    setHistory(response.data.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        }
    }



    const handleEdit = (index) => {
        setEditIndex(index);
        const ToEdit = history[index];
        setContent(ToEdit.content);
        setYear(ToEdit.year);
        setTitle(ToEdit.title);
    };

    const handleDelete = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const handleSave = (id) => {
        const formData = {
            year: year,
            title: title,
            content: content,
        }

        setEditIndex(-1);

        setLoading(true);
        axios
            .put(`api/History/${id}`, formData)
            .then(() => {
                axios.get(`/api/History`)
                    .then((response) => {
                        setHistory(response.data.data);
                        setLoading(false);
                    })

            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const sortedHistory = history.sort((a, b) => a.year - b.year);

    return (
        <>
            <div className='mt-3 text-center container-fluid'>
                <div className='fw-semibold rakete-font fs-1 text-uppercase headline'>Geschichte</div>
                <div className=''><HiOutlinePlus size={35} className='hover icon-edit' onClick={() => setAdd(true)} /></div>
            </div>
                <div className='container-fluid'>
                    <div className='justify-content-center'>
                        <div className="px-3 shadow-lg rounded-4 bg-white my-3 py-1">
                                    <div className=' fs-7 mx-2 pb-2'>
                                        <div className="text-center">
                                            {sortedHistory && sortedHistory.map((history, index) => (
                                                <div key={history._id} className="rounded-5 row py-2 align-items-center events justify-content-center">
                                                    {editIndex === index ? (
                                                        <>
                                                            <Row className="my-2 justify-content-center align-items-center carot-auto">
                                                                <div className='row'>
                                                                    <Form.Group className='col-4'>
                                                                        <Form.Label value="Vorname">Jahr</Form.Label>
                                                                        <Form.Control
                                                                            id="year"
                                                                            type="text"
                                                                            className='form-control-sm'
                                                                            value={year}
                                                                            onChange={(e) => setYear(e.target.value)}
                                                                        />
                                                                    </Form.Group >
                                                                    <Form.Group className='col'>
                                                                        <Form.Label value="Nachname" >Titel</Form.Label>
                                                                        <Form.Control
                                                                            id="title"
                                                                            type="text"
                                                                            className='form-control-sm'
                                                                            value={title}
                                                                            onChange={(e) => setTitle(e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="my-2">
                                                                        <Form.Label value="Content" >Inhalt</Form.Label>
                                                                        <Form.Control
                                                                            id="content"
                                                                            as="textarea"
                                                                            rows={2}
                                                                            value={content}
                                                                            onChange={(e) => setContent(e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                </div>
                                                                <div className="text-center my-3">
                                                                    <FaCheck size={25} className='hover icon-edit mx-2 my-1 check' onClick={() => handleSave(history._id)} />
                                                                    <IoClose size={28} className='hover icons mx-2 my-1 decline ' onClick={() => setEditIndex(-1)} />
                                                                </div>
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <div className='row d-flex justify-content-center align-items-center my-3'>
                                                            <div className='col-md-1 fw-semibold text-center rakete-font'>
                                                                {history.year}
                                                            </div>
                                                            <div className='col-md-3 text-center fw-semibold'>
                                                                {history.title}
                                                            </div>
                                                            <div className='col-md text-center'>
                                                                {history.content}
                                                            </div>
                                                            <div className="col-md-3 text-end align-items-center">
                                                                <TbEditCircle size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleEdit(index)} />
                                                                <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleDelete(history._id)} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                        </div>
                    </div>
                    <HistoryAdd show={add} onHide={handleAdd} />
                    <HistoryDelete show={showDelete} id={deleteId} onHide={handleAdd} />
                </div>
        </>
    )
}

export default History;
