import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import formattedDate from '../../helpers/formattedDate';

function ParticipantShow({ show, onHide, participant }) {

  if (!participant) {
    return null;
  }

  return (
    <Modal show={show} onHide={onHide} className="mx-auto" centered size="md">
      <Modal.Header className='border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6 '>{participant.teamname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className=' row bg-light align-items-center py-2 border-top'>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Name</div>
            <div className="col-auto text-start">{participant.firstname} {participant.lastname}</div>
          </div>
          <div className='row bg-light align-items-center py-2 border-top'>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Geburtstag</div>
            <div className="col-auto text-start">{formattedDate(participant.birthday)}</div>
          </div>
          <div className='row bg-light align-items-center py-2 border-top'>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Email</div>
            <div className="col-auto text-start">{participant.email}</div>
          </div>
          {participant.email1 && <div className='row bg-light align-items-center py-2 border-top'>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Email</div>
            <div className="col-auto text-start">{participant.email1}</div>
          </div>
          }
          <div className='row bg-light align-items-center py-2 border-top'>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Telefon</div>
            <div className="col-auto text-start">{participant.phoneNumber}</div>
          </div>
          <div className='row bg-light align-items-center py-2 border-top border-bottom'>
            <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Adresse</div>
            <div className="col-auto text-start">
              <div>{participant.street} {participant.houseNumber}</div>
              <div>{participant.postalCode} {participant.city}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
}
export default ParticipantShow;
