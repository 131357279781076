function getRoleLabel(role) {
  if (!role) {
    return "Mitglied"; // Fallback, wenn keine Rolle gesetzt ist
  }

  switch (role) {
    case 1:
      return "1. Vorstand";
    case 2:
      return "2. Vorstand";
    case 3:
      return "3. Vorstand";
    case 4:
      return "Leitung Erste";
    case 5:
      return "Leitung Zweite";
    case 6:
      return "Leitung Mixed";
    case 7:
      return "Leitung Frauen";
    case 8:
      return "Bunte - Liga Koordinator";
    case 9:
      return "Turnier - Team";
    case 10:
      return "Webmaster";
    default:
      return "Mitglied"; // Fallback für unerwartete Rollen
  }
}



function getRightsLabel(rights) {
  switch (rights) {
    case 0:
      return "user";
    case 1:
      return "operator";
    case 2:
      return "admin";
  }
}

function getTeamLabel(team) {
  switch (team) {
    case 0:
      return "Erste";
    case 1:
      return "Zweite";
    case 2:
      return "Mixed";
    case 3:
      return "Frauen";
    default:
      return "-";
  }
}

export { getRoleLabel, getRightsLabel, getTeamLabel };