import React, { useState } from 'react';
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import GameAdd from './GamesAdd';
import GameDelete from './GameDelete';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useAuth } from '../../provider/auth.js';

function Games({ games, opponents, onGameChange }) {
    const [loading, setLoading] = useState(false);
    const [editGameIndex, setEditGameIndex] = useState(-1);
    const [addGame, setAddGame] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteGameId, setDeleteGameId] = useState("");
    const [scored, setScored] = useState(0);
    const [conceded, setConceded] = useState(0);

    const { user, rights } = useAuth();

    const handleEditGame = (index) => {
        setEditGameIndex(index);
        const gameToEdit = games[index]; // Gegner, der bearbeitet werden soll
        setScored(gameToEdit.scored); // Setze neuen Namen
        setConceded(gameToEdit.conceded); // Setze neues Kürzel
    };

    const handleDeleteGame = (id) => {
        console.log(id);
        setDeleteGameId(id);
        setShowDelete(true)
    }

    const handleSaveGame = (id) => {
        const formData = {
            scored: scored,
            conceded: conceded,
        }

        setEditGameIndex(-1);

        setLoading(true);
        axios
            .put(`/api/Games/${id}`, formData)
            .then(() => {
                setLoading(false);
                onGameChange();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const formatGermanDate = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY HH:mm');
    };

    const getOpponentData = (opponentId) => {
        const opponent = opponents.find(opponent => opponent._id === opponentId);
        return opponent ? { name: opponent.short, picture: opponent.picture } : {};
    };

    const sortByDate = (games) => {
        const sortedGames = games.sort((a, b) => {
            const dateA = dayjs(a.date).toDate();
            const dateB = dayjs(b.date).toDate();
            return dateA - dateB;
        });
        return sortedGames;
    };

    const handleHide = (refresh) => {
        setAddGame(false);
        setShowDelete(false);
        if(refresh === true) {
            onGameChange();
        }
    }

    const sortedGames = sortByDate(games);

    return (
        <Accordion defaultActiveKey={null} className=''>
            <div className='container-fluid'>
                <div className='justify-content-center'>
                    <div className="mt-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className='border border-0'>
                                <div className='fw-semibold rakete-font fs-5 ms-3 text-start text-uppercase'>Spiele</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {rights === 2 && <div className='text-end mb-3'><HiOutlinePlus size={28} className='mx-1 hover icon-edit' onClick={() => setAddGame(true)} /></div>}
                                <div className=' fs-7 mx-1 pb-2'>
                                    <div className="text-center">
                                        {sortedGames && sortedGames.map((game, index) => (
                                            <div key={game._id} className=" newsline rounded-5 row py-2 d-flex align-items-center justify-content-center">
                                                {editGameIndex === index ? (
                                                    <>
                                                        <div className='col mt-2'>
                                                            <div className='mb-2 fs-8'>{formatGermanDate(game.date)}</div>
                                                            <div className='row row d-flex justify-content-center align-items-middle my-2'>
                                                                <div className="col-2 rakete-font fw-semibold">
                                                                    RAK
                                                                </div>
                                                                <Form.Group as={Col} xs={3}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className='form-control-sm text-cursor'
                                                                        maxLength={2}
                                                                        value={scored}
                                                                        onChange={(e) => setScored(e.target.value)}
                                                                    />
                                                                </Form.Group >
                                                                :
                                                                <Form.Group as={Col} xs={3}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className='form-control-sm text-cursor'
                                                                        maxLength={2}
                                                                        value={conceded}
                                                                        onChange={(e) => setConceded(e.target.value)}
                                                                    />
                                                                </Form.Group >
                                                                <div className="col-2 rakete-font fw-semibold">
                                                                    {getOpponentData(game.opponent).name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {rights === 2 &&<div className="col-3 text-center">
                                                            <FaCheck size={19} className='hover icon-edit mx-1 my-1 check' onClick={() => handleSaveGame(game._id)} />
                                                            <IoClose size={22} className='hover icons mx-1 my-1 decline ' onClick={() => setEditGameIndex(-1)} />
                                                        </div>}
                                                    </>
                                                ) : (
                                                    <div className='row d-flex justify-content-center'>
                                                        <div className='col mt-2'>
                                                            <div className='mb-2 fs-9'>{formatGermanDate(game.date)} - {game.location}</div>
                                                            <div className='row row d-flex justify-content-center align-items-middle my-2'>
                                                                <div className="col-3 rakete-font fw-semibold">
                                                                    RAK
                                                                </div>
                                                                <div className="col-4 fs-7 rakete-font fw-semibold ">
                                                                    {game.scored != null && game.conceded != null? `${game.scored}:${game.conceded}` : "- : -"}
                                                                </div>
                                                                <div className="col-3 rakete-font fw-semibold">
                                                                    {getOpponentData(game.opponent).name}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {rights === 2 && 
                                                        <div className="col-4 text-center align-items-center my-3">
                                                            <TbEditCircle size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleEditGame(index)} />
                                                            <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleDeleteGame(game._id)} />
                                                        </div>}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </div>
                </div>
                <GameAdd show={addGame} onHide={handleHide} opponents={opponents} />
                <GameDelete show={showDelete} id={deleteGameId} onHide={handleHide} />
            </div>
        </Accordion >
    )
}

export default Games;
