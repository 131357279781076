import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MemberDetail from './memberDetail';
import { getRightsLabel, getRoleLabel } from '../../helpers/role';
import MemberAdd from './memberAdd';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaSortAmountUp } from "react-icons/fa";
import { FaSortAmountDown } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import TextField from '@mui/material/TextField';
import { useAuth } from '../../provider/auth.js';



function Members() {
    const [members, setMembers] = useState([]);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [showMember, setShowMember] = useState(false);
    const [addMember, setAddMember] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [teamFilter, setTeamFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');
    const [erste, setErste] = useState(0)
    const [zweite, setZweite] = useState(0)
    const [mixed, setMixed] = useState(0)
    const [frauen, setFrauen] = useState(0)
    const [overall, setOverall] = useState(0)
    const [active, setActive] = useState(0)
    const { user, rights } = useAuth();

    useEffect(() => {
        loadMembers();
    }, []);

    useEffect(() => {
        handleFilterAndSort();
    }, [members, searchTerm, sortOrder, teamFilter, roleFilter]);

    const loadMembers = () => {
        setLoading(true);
        axios
            .get('/members')
            .then((response) => {
                const members = response.data.data;

                // Filter Mitglieder, deren exit-Datum bereits vergangen ist
                const filteredMembers = members.filter(member => {
                    // Prüfen, ob ein exit-Datum existiert und es bereits in der Vergangenheit liegt
                    return !member.exit || new Date(member.exit) > new Date();
                });

                setMembers(filteredMembers);
                setLoading(false);

                // Zähle Mitglieder pro Team
                const countErste = members.filter(member => member.team === 0).length;
                const countZweite = members.filter(member => member.team === 1).length;
                const countMixed = members.filter(member => member.team === 2).length;
                const countFrauen = members.filter(member => member.team === 3).length;
                const countActive = members.filter(member => member.active === true).length;
                const countOverall = members.length;

                // Setze die gezählten Werte in den State
                setErste(countErste);
                setZweite(countZweite);
                setMixed(countMixed);
                setFrauen(countFrauen);
                setActive(countActive);
                setOverall(countOverall);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const handleMemberDetail = (id) => {
        setShowMember(true);
        setSelectedMemberId(id);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = (order) => {
        setSortOrder(order);
    };

    const handleTeamFilter = (e) => {
        setTeamFilter(e.target.value);
    };

    const handleRoleFilter = (e) => {
        setRoleFilter(e.target.value);
    };

    const handleFilterAndSort = () => {
        let filtered = [...members];

        // Suche
        if (searchTerm) {
            filtered = filtered.filter(member =>
                member.forename.toLowerCase().includes(searchTerm.toLowerCase()) ||
                member.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                member.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Filter nach Mannschaft
        if (teamFilter !== '') {
            filtered = filtered.filter(member => member.team === parseInt(teamFilter));
        }

        // Filter nach Rolle
        if (roleFilter === 'vorstand') {
            filtered = filtered.filter(member => [1, 2, 3].includes(member.role));
        } else if (roleFilter === 'leitung') {
            filtered = filtered.filter(member => [4, 5, 6, 7].includes(member.role));
        } else if (roleFilter === 'andere') {
            filtered = filtered.filter(member => [8, 9, 10].includes(member.role));
        }

        // Zuerst sortieren nach dem gefilterten Wert (Team oder Rolle)
        if (roleFilter) {
            filtered = filtered.sort((a, b) => a.role - b.role);
        } else if (teamFilter !== '') {
            filtered = filtered.sort((a, b) => a.team - b.team);
        }

        // Sortieren nach Eintrittsdatum (auf- oder absteigend)
        if (sortOrder === 'asc') {
            filtered = filtered.sort((a, b) => {
                const dateA = (a.entryDate && a.entryDate !== "undefined") ? new Date(a.entryDate) : null;
                const dateB = (b.entryDate && b.entryDate !== "undefined") ? new Date(b.entryDate) : null;

                if (!dateA) return 1;  // Kein gültiges Datum -> ans Ende der Liste
                if (!dateB) return -1;
                return dateA - dateB;
            });
        } else if (sortOrder === 'desc') {
            filtered = filtered.sort((a, b) => {
                const dateA = (a.entryDate && a.entryDate !== "undefined") ? new Date(a.entryDate) : null;
                const dateB = (b.entryDate && b.entryDate !== "undefined") ? new Date(b.entryDate) : null;

                if (!dateA) return 1;  // Kein gültiges Datum -> ans Ende der Liste
                if (!dateB) return -1;
                return dateB - dateA;
            });
        }

        setFilteredMembers(filtered);
    };


    return (
        <div className='container-fluid'>
            <div className='row justify-content-center d-flex'>
                <div className="px-3 py-3">
                    <p className='fw-semibold rakete-font headline fs-1 text-center text-uppercase'>Mitglieder</p>
                    <div className='row pb-3 text-white'>
                        <div className='row text-center justify-content-center'>
                            <div className="col-auto my-2">
                                <div className='row'>
                                    <div className="col rakete-font fw-semibold border-end my-1">
                                        <div className='fw-semibold fs-7 text-dark'>ERSTE</div>
                                        <div>{erste}</div>
                                    </div>
                                    <div className="col rakete-font fw-semibold border-end my-1">
                                        <div className='fw-semibold fs-7 text-dark'>ZWEITE</div>
                                        <div>{zweite}</div>
                                    </div>
                                    <div className="col-auto my-1">
                                        <div className="row">
                                            <div className="col rakete-font fs-5 fw-semibold border-end ">
                                                <div className='fw-semibold fs-7 headline'>GESAMT</div>
                                                <div>{overall}</div>
                                            </div>
                                            <div className="col rakete-font fs-5 fw-semibold border-end">
                                                <div className='fw-semibold fs-7 h-green'>AKTIV</div>
                                                <div>{active}</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col rakete-font fw-semibold border-end my-1">
                                        <div className='fw-semibold fs-7 text-dark'>MIXED</div>
                                        <div>{mixed}</div>
                                    </div>
                                    <div className="col rakete-font fw-semibold my-1">
                                        <div className='fw-semibold fs-7 text-dark'>FRAUEN</div>
                                        <div>{frauen}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mx-auto bg-white pt-3 rounded-top-5'>
                        <div className='text-center align-items-center justify-content-center d-flex my-4 mx-3 bg-white'>
                            <TextField id="searchTerm" label="Suche" variant="outlined" value={searchTerm} onChange={handleSearch} sx={{ width: '300px', height: '50px' }} />
                            {rights === 2 && <IoMdPersonAdd size={32} className='hover icon-edit mx-3' onClick={() => setAddMember(true)} />}
                        </div>
                        <div className='d-flex justify-content-center row py-3'>
                            <div className='col-auto'>
                                <Form.Group>
                                    <Form.Label className="fs-8 rakete-font fw-semibold">TEAM</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        type="text"
                                        className='form-control-sm'
                                        value={teamFilter}
                                        onChange={handleTeamFilter}
                                    >
                                        <option value="">Alle</option>
                                        <option value="0">Erste</option>
                                        <option value="1">Zweite</option>
                                        <option value="2">Mixed</option>
                                        <option value="3">Frauen</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='col-auto'>
                                <Form.Group>
                                    <Form.Label className="fs-8 rakete-font fw-semibold">FUNKTION</Form.Label>
                                    <Form.Select
                                        size="sm"
                                        type="text"
                                        className='form-control-sm'
                                        value={roleFilter}
                                        onChange={handleRoleFilter}
                                    >
                                        <option value="">Keine</option>
                                        <option value="vorstand">Vorstand</option>
                                        <option value="leitung">Leitung</option>
                                        <option value="andere">Andere</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='col-auto'>
                                <Form.Group>
                                    <Form.Label className="fs-8 rakete-font fw-semibold">EINTRITT</Form.Label>
                                    <div>
                                        <FaSortAmountDown className='me-2' size={22} onClick={() => handleSort('asc')} />
                                        <FaSortAmountUp className='ms-2' size={22} onClick={() => handleSort('desc')} />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className='fs-7 pb-2 bg-white px-4 rounded-bottom-5'>
                        <div className="text-center">
                            {filteredMembers.length === 0 ?
                            <div className='fw-semibold fs-4 py-5 text-secondary rakete-font'>Kein Mitglied gefunden</div> : filteredMembers.map((member) => (
                                <div key={member._id} className=" row py-2 d-flex border-top align-items-center justify-content-center" onClick={() => handleMemberDetail(member._id)}>
                                    <div className='col-auto my-2'>
                                        <img className={member.rights === 1 ? " avatar-p border border-2 border-warning" : member.rights === 2 ? " avatar-p border border-2 border-primary" : `avatar-p border border-2`} src={`https://server.raketefreiburg.de/uploads/${member.picture}`} alt={`${member.forename}`} />
                                    </div>
                                    <div className='col fs-7 text-start text-wrap fw-semibold'>{member.forename} {member.lastname}</div>
                                    <div className='col-3 fs-8 text-start fw-semibold text-end'>{member.role !== 0 && member.role && getRoleLabel(member.role)}</div>
                                    <div className={member.active === true ? "col-2 fs-8 rakete-font fw-semibold text-success" : "col-2 fs-8 rakete-font fw-semibold text-warning"}>{member.active === true ? "AKTIV" : "PASSIV"}</div>
                                </div>
                            ))}
                            
                        </div>
                    </div>
                </div>
            </div>
            <MemberDetail show={showMember} onHide={() => setShowMember(false)} member={members.find(item => item._id === selectedMemberId)} />
            <MemberAdd show={addMember} onHide={() => setAddMember(false)} setMembers={setMembers} />
        </div>
    );
}

export default Members;
