import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

function OpponentAdd({ show, onHide }) {
    const [name, setName] = useState('');
    const [picture, setPicture] = useState('');
    const [short, setShort] = useState('');
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState();

    function handleChange(event) {
        setPicture(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
    }

    const closeOpponentsAdd = () => {
        setPreview();
        onHide();
    }

    const handleAddOpponent = () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('logo', picture);
        formData.append("short", short);

        setLoading(true);

        axios
            .post("/api/Opponents", formData)
            .then(() => {
                setLoading(false);
                setPreview();
                onHide(true);
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occurred. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeOpponentsAdd} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeOpponentsAdd}>Gegner hinzufügen</Modal.Header>
                <Modal.Body className='fs-7'>
                    <img src={preview} className="img-fluid img-preview" />
                    <Form.Group>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8">Logo</Form.Label>
                        <Form.Control className="form-control-sm" id="file" type="file" onChange={handleChange} accept="image/*" />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8">Name</Form.Label>
                        <Form.Control
                            id="name"
                            type="text"
                            className='form-control-sm'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group >
                    <Form.Group as={Col} xs={2}>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8">Kürzel</Form.Label>
                        <Form.Control
                            id="short"
                            type="text"
                            className='form-control-sm'
                            value={short}
                            maxLength={3}
                            onChange={(e) => {
                                setShort(e.target.value);
                            }}
                        />
                    </Form.Group >
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddOpponent} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeOpponentsAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>

    );
};

export default OpponentAdd;
