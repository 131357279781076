import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { toast } from "react-toastify";
import { useAuth } from '../provider/auth.js';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


function Login() {
    const { login } = useAuth();

    const [values, setValues] = useState({ email: "", password: "" });
    const [forgotPassword, setForgotPassword] = useState(false);
    const [email, setEmail] = useState("")

    const handleLogin = async (event) => {
        event.preventDefault();
        await login(values.email, values.password);
    };

    const handleReset = () => {
        const formData = { email };
        axios
            .post("/members/reset-password", formData)
            .then((response) => {
                toast.success(`${response.data.message}`);
                setForgotPassword(false);
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`);
                console.log(error);
            });
    };

    return (
        <div className='center-container container-fluid no-text-cursor'>
            <div className='bg-white px-5 rounded-5 shadow-lg'>
                <div className='hover'>
                    <div className='col logo-container pt-4'>
                        <img className="logo mb-4 mt-3" src={"https://server.raketefreiburg.de/logo/logo-ohne-schriftzug.png"} alt="logo" />
                    </div>
                    <div className='col text-dark rakete-font logo-container fs-5 logo-text'>
                        <b className="rakete-rot">RAKETE</b>
                        <b>FREIBURG</b>
                    </div>
                </div>
                {!forgotPassword ? (
                    <Form onSubmit={handleLogin}>
                        <Form.Group className="mb-2" controlId="formGroupEmail">
                            <Form.Label className='text-uppercase fs-8 fw-semibold rakete-font'>Email</Form.Label>
                            <Form.Control className="form-control-sm" type="text" autoComplete="email" placeholder="max.mustermann@abc.de" name="email" onChange={e => setValues({ ...values, [e.target.name]: e.target.value })} required />
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <Form.Label className='text-uppercase fs-8 fw-semibold rakete-font'>Passwort</Form.Label>
                            <Form.Control className="form-control-sm" type="password" autoComplete="current-password" placeholder="****" name="password" onChange={e => setValues({ ...values, [e.target.name]: e.target.value })} required />
                            <div className='center mt-4'>
                                <button className="sign-button text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" type="submit">Login</button>
                            </div>
                        </Form.Group>
                        <p className='fs-7 text-center mt-3 forgot-password' onClick={() => setForgotPassword(true)}>Passwort vergessen?</p>
                    </Form>
                ) : (
                    <>
                        <Form>
                            <Form.Group className="mb-2" controlId="formGroupEmail">
                                <Form.Label className='text-uppercase fs-8 fw-semibold rakete-font'>Email</Form.Label>
                                <Form.Control className="form-control-sm" type="text" autoComplete="email" placeholder="max.mustermann@abc.de" name="email" onChange={(e) => setEmail(e.target.value)} required />
                            </Form.Group>
                        </Form>
                        <div className='center mt-4'>
                            <button className="sign-button text-center login-button text-uppercase rounded fw-semibold border border-0 fs-8" onClick={handleReset}>Passwort zurücksetzen</button>
                        </div>
                        <p className='fs-7 text-center mt-3 forgot-password' onClick={() => setForgotPassword(false)}>Zurück zum Login</p>
                    </>
                )}
            </div>
        </div>
    );
}

export default Login;
