import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Login from "../pages/Login.jsx";
import LoadingSpinner from "../components/spinner.jsx";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [rights, setRights] = useState(-1);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get("/auth/check");
        setUser(data.user);
        setRights(data.rights);

  
        if(!data.status){
          setUser(null);
          setRights(null);
        }
      } catch (error) {
        setUser(null);
        setRights(null);
      } finally {
        setLoading(false);
      }
    };
    if (!user && loading) {
      checkAuth(); 
    }
  }, [user, rights, loading]);

  const login = async (email, password) => {
    try {
      const { data } = await axios.post("/auth/login", { email, password });
      setUser(data.user);
      setRights(data.rights);
      navigate("/");
      toast.success("Erfolgreich eingeloggt!");
    } catch (error) {
      toast.error(error.response.data.message || "Benutzername und/oder Passwort falsch")
    }
  };

  const logout = async () => {
    try {
      await axios.post("/auth/logout");
      setUser(null);
      setRights(null);
      navigate("/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };


  // Überprüfen, ob der Benutzer authentifiziert ist und entscheiden, was gerendert werden soll
  const renderContent = () => {
    if (!user && !loading) {
      // Benutzer ist nicht authentifiziert und die Überprüfung ist abgeschlossen
      return <Login />;
    } else if (user) {
      // Benutzer ist authentifiziert
      return children;
    } else {
      // Benutzer ist nicht authentifiziert und die Überprüfung läuft noch
      return <LoadingSpinner />;
    }
  };

  return <AuthContext.Provider value={{ user, rights, login, logout }}>{renderContent()}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
