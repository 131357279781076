import React from 'react'
import Navibar from '../components/navbar'
import { Outlet } from "react-router-dom";

const home = () => {
  return (
    <>
    <Navibar/>
    <Outlet/>
    </>
  )
  
}

export default home