import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

function TournamentDelete({ show, id, onHide }) {
  const [loading, setLoading] = useState(false);

  const handleTournamentDelete = () => {
    setLoading(true);  // Setze den Ladezustand, bevor die Anfrage abgeschickt wird

    axios
        .delete(`/api/Tournaments/${id}`)
        .then(() => {
            setLoading(false);
            window.location.href = '/events';
        })
        .catch((error) => {
            setLoading(false);
            alert("An error occurred. Please check Console");
            console.log(error);
        });
}

  if (!show) {
    return null
  } else {
    return (
      <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header className='rakete-font text-uppercase fw-semibold rakete-rot' closeButton>Turnier löschen</Modal.Header>
      <Modal.Body className='mt-2'>
            Bist du dir sicher, dass du dieses Turnier löschen möchtest. Es kann nicht rückgängig gemacht werden?
      </Modal.Body>
      <Modal.Footer className='border border-0'>
        <button onClick={handleTournamentDelete} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" disabled={loading}>Löschen</button>
        <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={onHide} disabled={loading}>
          Verwerfen
        </button>
      </Modal.Footer>
    </Modal>
    );
  }
}
export default TournamentDelete;