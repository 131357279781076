import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { useAuth } from '../../provider/auth';

function MemberEdit({ showEdit, member }) {
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [role, setRole] = useState('');
    const [rights, setRights] = useState(0);
    const [user, setUser] = useState(false);
    const [active, setActive] = useState(true);
    const [special, setSpecial] = useState(false);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState();
    const [picture, setPicture] = useState("");
    const [newPicture, setNewPicture] = useState("");
    const [team, setTeam] = useState();
    const [gender, setGender] = useState('');
    const [documentConfirmed, setDocumentConfirmed] = useState(false); 
    const [memberFile, setMemberFile] = useState(null);

    const { user: loggedInUser, rights: loggedInUserRights } = useAuth();

    function handleChange(event) {
        setNewPicture(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
    }

    useEffect(() => {
        if (member) {
            setForename(member.forename);
            setLastname(member.lastname);
            setEmail(member.email);
            setPhoneNumber(member.phoneNumber);
            setStreet(member.address?.street);
            setHouseNumber(member.address?.houseNumber);
            setCity(member.address?.city);
            setPostalCode(member.address?.postalCode);
            setEntryDate(member.entryDate);
            setRole(member.role);
            setRights(member.rights);
            setTeam(member.team);
            setGender(member.gender);
            setDocumentConfirmed(member.documents?.available);
            if (member.user) {
                setUser(member.user);
            }
            setMemberFile(member.documents?.path);
            setActive(member.active);
            setSpecial(member.special);
            setPicture(member.picture);
        }
    }, [member]);

    const handleEditMember = () => {
        const formData = new FormData();
        formData.append("forename", forename);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("phoneNumber", phoneNumber);
        formData.append("street", street);
        formData.append("houseNumber", houseNumber);
        formData.append("city", city);
        formData.append("postalCode", postalCode);
        formData.append("entryDate", entryDate);
        formData.append("role", role);
        formData.append('team', team);
        formData.append("rights", rights);
        formData.append("user", user);
        formData.append("active", active);
        formData.append("special", special);
        formData.append("gender", gender);
        formData
        formData.append("documentsAvailable", documentConfirmed);
        if (newPicture) {
            formData.append("picture", newPicture);
        }
        if (memberFile) {
            formData.append('memberFile', memberFile);
        }
        
        setLoading(true);

        axios
            .put(`/members/${member._id}`, formData)
            .then(() => {
                setLoading(false);
                setPreview();
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                alert("Ein Fehler ist aufgetreten. Bitte prüfe die Konsole.");
                console.log(error);
            });

    };

    if ((!member && showEdit) || (member && !showEdit)) {
        return null;
    }

    return (
        <div>
            {(!preview) ? (
                <img src={`https://server.raketefreiburg.de/uploads/${picture}`} className="profile-image mb-3" alt={`Profilbild: ${member.forename}`} />
            ) : (
                <img src={preview} className="profile-image mb-3" alt="Profilbild Vorschau" />
            )}
            {loggedInUser === member._id && (
                <div id="fileUpload" className="my-2">
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Profilbild</Form.Label>
                    <Form.Control
                        className="form-control-sm"
                        id="file"
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                    />
                </div>
            )}
    
            <Form.Group className="my-2">
                <Form.Label>Geschlecht</Form.Label>
                <Form.Select
                    size="sm"
                    className='form-control-sm'
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                >
                    <option value="0">Mann</option>
                    <option value="1">Frau</option>
                    <option value="2">Divers</option>
                </Form.Select>
            </Form.Group>
    
            <Row className="my-2">
                <Form.Group as={Col}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Vorname</Form.Label>
                    <Form.Control
                        id="forename"
                        type="text"
                        className='form-control-sm'
                        value={forename}
                        onChange={(e) => setForename(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Nachname</Form.Label>
                    <Form.Control
                        id="lastname"
                        type="text"
                        className='form-control-sm'
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                </Form.Group>
            </Row>
    
            <Form.Group className="my-2">
                <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Email</Form.Label>
                <Form.Control
                    id="email"
                    type="email"
                    className='form-control-sm'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
    
            <Form.Group className="my-2">
                <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Telefonnummer</Form.Label>
                <Form.Control
                    id="phoneNumber"
                    type="text"
                    className='form-control-sm'
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </Form.Group>
    
            <Row className="my-2">
                <Form.Group as={Col}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Straße</Form.Label>
                    <Form.Control
                        id="street"
                        type="text"
                        className='form-control-sm'
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col} xs={3}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Nr.</Form.Label>
                    <Form.Control
                        id="houseNumber"
                        type="text"
                        className='form-control-sm'
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                    />
                </Form.Group>
            </Row>
    
            <Row className="my-2">
                <Form.Group as={Col} xs={5}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Postleitzahl</Form.Label>
                    <Form.Control
                        id="postalCode"
                        type="text"
                        className='form-control-sm'
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Stadt</Form.Label>
                    <Form.Control
                        id="city"
                        type="text"
                        className='form-control-sm'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </Form.Group>
            </Row>
    
            <Form.Group className="my-2">
                <Form.Label>Team</Form.Label>
                <Form.Select
                    size="sm"
                    type="text"
                    className='form-control-sm'
                    value={team}
                    onChange={(e) => setTeam(e.target.value)}
                >
                    <option value="">Team wählen</option>
                    <option value="0">Erste</option>
                    <option value="1">Zweite</option>
                    <option value="2">Mixed</option>
                    <option value="3">Frauen</option>
                </Form.Select>
            </Form.Group>
    
            <Form.Group className="my-2">
                <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Eintrittsdatum</Form.Label>
                <Form.Control
                    id="entryDate"
                    type="date"
                    className='form-control-sm'
                    value={entryDate}
                    onChange={(e) => setEntryDate(e.target.value)}
                />
            </Form.Group>
    
            <Row className="my-2">
                <Form.Group as={Col}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Rechte</Form.Label>
                    <Form.Select
                        size="sm"
                        className='form-control-sm'
                        value={rights}
                        onChange={(e) => setRights(e.target.value)}
                    >
                        <option value="0">Keine</option>
                        <option value="1">Operator</option>
                        <option value="2">Admin</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Rolle</Form.Label>
                    <Form.Select
                        size="sm"
                        className='form-control-sm'
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option value="">Wähle eine Rolle</option>
                        <option value="0">Keine</option>
                        <option value="1">1. Vorstand</option>
                        <option value="2">2. Vorstand</option>
                        <option value="3">3. Vorstand</option>
                        <option value="4">Leitung Erste</option>
                        <option value="5">Leitung Zweite</option>
                        <option value="6">Leitung Mixed</option>
                        <option value="7">Leitung Frauen</option>
                        <option value="8">Bunte - Liga Koordinator</option>
                        <option value="9">Turnier - Team</option>
                        <option value="10">Webmaster</option>
                    </Form.Select>
                </Form.Group>
            </Row>
    
            <Form.Group className="my-2">
                <Form.Check
                    type="switch"
                    label={active === true ? "Aktiv" : "Passiv"}
                    value={active}
                    checked={active === true}
                    onChange={() => setActive(!active)}
                />
            </Form.Group>
    
            <Form.Group className="my-2">
                <Form.Label>Ehren- oder Fördermitglied?</Form.Label>
                <Form.Check
                    type="switch"
                    label={special === true ? "Ja" : "Nein"}
                    value={special}
                    checked={special === true}
                    onChange={() => setSpecial(!special)}
                />
            </Form.Group>
    
            <Form.Group className="my-2">
                <Form.Label>Mitgliedsantrag hochladen</Form.Label>
                <Form.Control
                    className="form-control-sm"
                    id="file"
                    type="file"
                    accept=".pdf,.png,.jpeg"
                    onChange={(e) => setMemberFile(e.target.files[0])}
                />
            </Form.Group>
    
            <div className='pt-3 div-justify-middle'>
                <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" type="button" onClick={handleEditMember}>
                    Speichern
                </button>
            </div>
        </div>
    );
    
}    

export default MemberEdit;
