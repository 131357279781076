import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdOutlinePaid } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi";
import { TbEditCircle } from "react-icons/tb";
import TournamentEdit from '../components/turniere/TournamentEdit';
import PartificantAdd from '../components/turniere/partificantAdd';
import { FaRegCalendarCheck } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdArrowCircleUp } from "react-icons/md";
import TournamentDelete from '../components/turniere/tournamentDelete';
import { MdCheckCircleOutline } from "react-icons/md";
import ParticipantShow from "../components/turniere/partificantShow";

function Tournaments() {
    const [tournament, setTournament] = useState({});
    const [loading, setLoading] = useState(true);
    const [participants, setParticipants] = useState([]);
    const [participantsConfirmed, setParticipantsConfirmed] = useState([]);
    const [participantsWait, setParticipantsWait] = useState([]);
    const [participantsCanceled, setParticipantsCanceled] = useState([]);
    const [inquiry, setInquiry] = useState([]);
    const [editTournament, setEditTournament] = useState(false);
    const [addPartificant, setAddPartificant] = useState(false);
    const [deleteTournament, setDeleteTournament] = useState(false);
    const [showParticipant, setShowParticipant] = useState(false);
    const [participant, setParticipant] = useState([]);
    const [status, setStatus] = useState(1);

    const { id } = useParams();

    const formatGermanDateAndTime = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY HH:mm');
    };

    const formatGermanDate = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY');
    };

    useEffect(() => {
        axios
            .get(`/tournament/${id}`)
            .then((response) => {
                setTournament(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [id]);

    const getParticipants = () => {
        axios
            .get(`/tournament/${id}/participants`)
            .then((response) => {
                const data = response.data.data;
                setParticipants(data);
                setParticipantsConfirmed(data.filter(participant => participant.status === 1));
                setParticipantsCanceled(data.filter(participant => participant.status === 3));
                setParticipantsWait(data.filter(participant => participant.status === 2));
                setInquiry(data.filter(participant => participant.status === 0 || !participant.status ));
                if(participantsConfirmed.length >= tournament.teams){
                    setStatus(2);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getParticipants();
    }, [id, tournament.teams]);

    function handleParticipantUpdate(id, participantId, field, value) {
        setLoading(true);
        const formData = {[field]: value }

        axios
            .put(`/tournament/${id}/participants/${participantId}`, formData)
            .then(() => {
                getParticipants();
                setLoading(false);


            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    function handleParticipantDelete(id, participantId) {
        setLoading(true);

        axios
            .delete(`/tournament/${id}/participants/${participantId}`)
            .then(() => {
                setLoading(false);
                getParticipants();
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    const openModal = (topic) => {
        setTopic(topic);
        setShowModal(true);
        
    }

    const closeModal = (refresh) => {
        setTopic();
        setShowModal(false);

        if(refresh === true) {
            axios
            .get(`/tournament/${id}`)
            .then((response) => {
                setTournament(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
        
    }

    const handleChange = () => {

        const formData = {
          completed: !tournament.completed
        }
      
        axios
            .put(`api/Tournaments/${id}`, formData)
            .then(() => {
                axios
            .get(`/tournament/${id}`)
            .then((response) => {
                setTournament(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });                
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });
      };

    const handleParticipantShow = (participant) => {
        setParticipant(participant);
        setShowParticipant(true);
    }

    return (
        <>
                <div className='row justify-content-center mx-3'>
                    <div className='row align-items-center'>
                        <div className="col-1"><Link to={"/events"}><IoMdArrowRoundBack size={40} className="back-button" /></Link></div>
                        <div className='col-9'>
                            <p className='rakete-font fw-bold mt-4 fs-1 text-center text-uppercase headline'>{tournament.name}</p>
                        </div>
                    </div>
                    <div className='col-lg-5 rounded-4 bg-white shadow-lg mt-2 mx-2'>
                        <div className='text-center rakete-font fs-5 my-3 text-uppercase fw-semibold'>Infos</div>

                        <div className='my-2 text-center'><img className="tournament-thumb rounded shadow-sm" src={`https://server.raketefreiburg.de/uploads/${tournament.picture}`} alt={`${tournament.name}`} /></div>
                            <div className="text-center rakete-font">{tournament.location}</div>
                        <div className="text-center rakete-font">{formatGermanDateAndTime(tournament.date)} Uhr</div>
                        <div className='row shadow-sm bg-light rounded my-3'>
                            <div className='col-md'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="2" className='text-center rakete-font text-uppercase fs-7 fw-semibold'>Regeln</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='rakete-font fs-9'>Teams</td>
                                            <td className="fs-6 text-end">{tournament.teams}</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Spieleranzahl</td>
                                            <td className='fs-6 text-end'>{tournament.players}</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Teilnahmegebühr</td>
                                            <td className='fs-6 text-end'>{tournament.prize}€</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="2" className='text-center rakete-font text-uppercase fs-7 fw-semibold'>Teams</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='rakete-font fs-9'>Anmeldungen</td>
                                            <td className="fs-6 text-end">{participants.length}</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Zugelassen</td>
                                            <td className='fs-6 text-end'>{participantsConfirmed.length}</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Warteliste</td>
                                            <td className='fs-6 text-end'>{participantsWait.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className=''>
                                <table className="table ">
                                    <tbody>
                                        <tr>
                                            <td className='rakete-font fs-9'>Modus</td>
                                            <td className='fs-6 text-end'>{tournament.modus === 0 ? "Liga" : tournament.modus === 1 ? "KO-Runde" : tournament.modus === 2 ? "Liga + KO- Runde" : "-"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='text-uppercase fw-semibold rakete-font text-center'>Infotext</div>
                        <div className='mx-3 mt-3 mb-4'>{tournament.info}</div>
                        <div className='text-center py-3 border-top'>
                            <FaRegCalendarCheck size={22} className={tournament.completed === true ? 'icon-edit mx-3 text-danger' : "icon-edit mx-3 text-success"} onClick={() => handleChange()} title={tournament.completed === true ? "Anmeldung deaktiviert" : "Anmeldung offen"} />
                            <TbEditCircle size={25} className='icon-edit mx-3' onClick={() => setEditTournament(true)} title="Turnier bearbeiten" />
                            <RiDeleteBinLine size={25} className='icon-edit mx-3' onClick={() => setDeleteTournament(true)} title="Turnier löschen" />
                            <HiOutlinePlus size={25} className='mx-4 hover icon-edit' onClick={() => setAddPartificant(true)} title="Teilnehmer hinzufügen" />
                        </div>
                    </div>
                    <div className='col-lg-6 rounded-4 bg-white shadow-sm mt-2 mx-2 py-3'>
                        {inquiry.length !== 0 && (
                            <div>
                                <div className='row mb-1'>
                                    <div className='col text-end rakete-font fs-5 text-uppercase fw-semibold'>Anfragen</div>
                                    <div className='col-5 fw-semibold'>( {inquiry.length} )</div>
                                </div>
                                <div className="text-center">
                                    {inquiry && inquiry.map((participant, index) => (
                                        <div key={participant._id} className=" newsline rounded-5 row mx-2 d-flex align-items-center justify-content-center">
                                            <div className="col-5 rakete-font fs-7 text-start fw-semibold py-2" onClick={() => handleParticipantShow(participant)}>{participant.teamname}</div>
                                            <div className="col-3 text-end text-wrap fs-9 py-2" onClick={() => handleParticipantShow(participant)}>{formatGermanDate(participant.createdAt)}</div>

                                            <div className="col-4 text-end py-2">
                                            <MdOutlinePaid size={23} className={participant.paid === true ? 'hover icon-edit mx-1 my-1 text-success' : 'hover icon-edit mx-1 my-1 text-danger'} onClick={() => handleParticipantUpdate(id, participant._id, "paid", !participant.paid)} title={participant.paid === true ? "Teilnahmegebühr bezahlt" : "Teilnahmegebühr offen"} />
                                            {participant.paid === true ? <MdCheckCircleOutline size={23} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantUpdate(id, participant._id, "status", status)} title="Anfrage aktzeptieren" /> : <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleParticipantDelete(id, participant._id)} title="Anfrage löschen" />
                                        }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div>
                            <div className='row mt-3 mb-1'>
                                <div className='col text-end rakete-font fs-5 text-uppercase fw-semibold '>Teilnehmer</div>
                                <div className='col-3 fw-semibold'>( {participantsConfirmed.length} / {tournament.teams} )</div>
                                <div className='col-2 fw-semibold'></div>
                            </div>
                            <div className="text-center">
                                {participantsConfirmed && participantsConfirmed.map((team, index) => (
                                    <div key={team._id} className=" newsline rounded-5 row mx-2 d-flex align-items-center justify-content-center">
                                        <div className="col-5 rakete-font fs-7 fw-semibold text-start text-success py-2" onClick={() => handleParticipantShow(team)}>{team.teamname}</div>
                                        <div className="col-3 text-end text-wrap fs-9 py-2" onClick={() => handleParticipantShow(team)}>{formatGermanDate(team.createdAt)}</div>
                                        <div className="col-4 text-end py-2">
                                            <MdArrowCircleUp size={23} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantUpdate(id, team._id,"status", 0)} title="Status auf Anfrage ändern" />
                                            <IoMdCloseCircleOutline size={24} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantUpdate(id, team._id,"status", 3)} title="Teilnehmer vom Turnier abmelden" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {participantsWait.length !== 0 && (
                            <div>
                                <div className='row mt-3'>
                                    <div className='col'><p className='text-end rakete-font fs-5 text-uppercase fw-semibold '>Warteliste</p></div>
                                    <div className='col-5'><p className='fw-semibold'>( {participantsWait.length} )</p></div>
                                </div>
                                <div className="text-center">
                                    {participantsWait && participantsWait.map((participant, index) => (
                                        <div key={participant._id} className=" newsline rounded-5 mx-2 row d-flex align-items-center justify-content-center">
                                            <div className="col-5 rakete-font fs-7 fw-semibold text-start text-warning py-2" onClick={() => handleParticipantShow(participant)}>{participant.teamname}</div>
                                            <div className="col-4 text-end text-wrap fs-9 py-2" onClick={() => handleParticipantShow(participant)}>{formatGermanDate(participant.createdAt)}</div>
                                            <div className="col-3 text-end py-2">
                                            <MdArrowCircleUp size={23} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantUpdate(id, team._id,"status", 0)} title="Status auf Anfrage ändern" />
                                            <IoMdCloseCircleOutline size={24} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantUpdate(id, team._id,"status", 3)} title="Teilnehmer vom Turnier abmelden" />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {participantsCanceled.length !== 0 && (<div>
                            <div className='row mt-3'>
                                <div className='col'><p className='text-end rakete-font fs-5 text-uppercase fw-semibold '>Abmeldungen</p></div>
                                <div className='col-5'><p className='fw-semibold'>( {participantsCanceled.length} )</p></div>
                            </div>
                            <div className="text-center">
                                {participantsCanceled && participantsCanceled.map((participant, index) => (
                                    <div key={participant._id} className=" newsline rounded-5 mx-2 row justify-content-center align-items-center">
                                        <div className="col-5 rakete-font fs-7 fw-semibold text-start text-danger py-2" onClick={() => handleParticipantShow(participant)}>{participant.teamname}</div>
                                        <div className="col-4 text-end text-wrap fs-9 py-2" onClick={() => handleParticipantShow(participant)}>{formatGermanDate(participant.createdAt)}</div>
                                        <div className="col-3 text-end py-2">
                                        <MdOutlinePaid size={23} className={participant.paid === true ? 'hover icon-edit mx-1 my-1 text-success' : 'hover icon-edit mx-1 my-1 text-danger'} onClick={() => handleParticipantUpdate(id, participant._id,"paid", !participant.paid)} title={participant.paid === true ? "Teilnahmegebühr bezahlt" : "Teilnahmegebühr offen"} />
                                            {participant.paid === true ? 
                                                <MdArrowCircleUp size={23} className='hover icon-edit mx-1 my-1 fw-semibold' onClick={() => handleParticipantUpdate(id, participant._id,"status",status)} title="Teilnehmer wieder anmelden" /> : <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleParticipantDelete(id, participant._id)} title="Teilnehmer löschen" />
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            <TournamentEdit show={editTournament} onHide={() => setEditTournament(false)} tournament={tournament} />
            <PartificantAdd show={addPartificant} onHide={() => setAddPartificant(false)} refresh={() => getParticipants()} tournamentid={tournament._id} />
            <TournamentDelete show={deleteTournament} onHide={() => setDeleteTournament(false)} id={tournament._id}/>
            <ParticipantShow show={showParticipant} onHide={() => setShowParticipant(false)} participant={participant}/>
        </>
    );
}

export default Tournaments;
