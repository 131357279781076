import React from 'react';

function Score({ games }) {

    function calculateStatsAndFilterUndefined(games) {
        let p = 0;
        let w = 0;
        let d = 0;
        let l = 0;
        let gd = 0;
        let pts = 0;

        games.forEach(game => {
                if (typeof game.scored === 'number' && typeof game.conceded === 'number') {
                    p++;
                    if (game.scored > game.conceded) {
                        w++;
                        pts = pts + 2; 
                    } else if (game.scored === game.conceded) {
                        d++;
                        pts = pts + 1;
                    } else {
                        l++;
                    }
                    gd += game.scored - game.conceded;
                }
            }
        );

        return { p, w, d, l, gd, pts};
    }

    const stats = calculateStatsAndFilterUndefined(games);

    return (
        <div className='container-fluid'>
            <div className='shadow-lg rounded-4 bg-white'>
                <p className='fw-semibold rakete-font text-danger fs-4 text-start text-uppercase text-center pt-3'>Score</p>
                <div>
                <div className="pb-1">
                        <table className="table rakete-font text-center">
                            <thead>
                                <tr className="table-light">
                                    <th>P</th>
                                    <th className="">W</th>
                                    <th>D</th>
                                    <th>L</th>
                                    <th>GD</th>
                                    <th>PTS</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <td>{stats.p}</td>
                                    <td>{stats.w}</td>
                                    <td>{stats.d}</td>
                                    <td>{stats.l}</td>
                                    <td>+{stats.gd}</td>
                                    <td>{stats.pts}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Score;