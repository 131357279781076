import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

function HistoryDelete({ show, id, onHide }) {
    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        setLoading(true);
        axios
            .delete(`/api/History/${id}`)
            .then(() => {
                setLoading(false);
                onHide(true);

            })
            .catch(error => {
                setLoading(false);
                console.error('Error deleting History:', error);
            });
    };

    if (!show) {
        return null
    } else {
        return (
            <Modal show={show} onHide={onHide} size="sm">
                <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
                    <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6'>Ereignis löschen?</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light shadow'>
                    <div className='mt-2 mx-2 text-center'>
                        Bist du dir sicher, dass du dieses Ereignis löschen möchtest?
                    </div>
                </Modal.Body>
                <Modal.Footer className='border border-0 fs-8'>
                    <div className='pt-3 div-justify-middle'>
                        <button onClick={handleDelete} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" disabled={loading}>Löschen</button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default HistoryDelete;