import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { useAuth } from '../../provider/auth';
import { toast } from 'react-toastify';

function MemberPassword({ show, id }) {
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState("");
    const [rights, setRights] = useState(0);
    const [loading, setLoading] = useState(false);

    const { user, logout, rights: right } = useAuth();

    const handleChangePassword = () => {
        if (newPassword !== validatePassword) {
            toast.error("Die neuen Passwörter stimmen nicht überein");
            return;
        }
        const formData = {
            newPassword, oldPassword
        }

        setLoading(true);

        axios
            .post(`/auth/change-password`, formData)
            .then(() => {
                setLoading(false);
                window.location.reload();
                toast.success("Passwort erfolgreich geändert")
            })
            .catch((error) => {
                setLoading(false);
                toast.error(`${error.response.data}`);
                console.log(error);
            });

    };
    
    if(id === user && show ){
        return (
            <div>
                <Row className="my-2">
                <Form.Group className="my-2">
                    <Form.Label value="Email" className='fs-8 text-uppercase rakete-rot rakete-font' >altes Passwort</Form.Label>
                    <Form.Control
                        id="password"
                        type="password"
                        className='form-control-sm'
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="my-2">
                    <Form.Label value="Email" className='fs-8 text-uppercase rakete-rot rakete-font' >Neues Passwort</Form.Label>
                    <Form.Control
                        id="newpassword"
                        type="password"
                        className='form-control-sm'
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="my-2">
                    <Form.Label value="Email" className='fs-8 text-uppercase rakete-rot rakete-font' >Neues Passwort wiederholen</Form.Label>
                    <Form.Control
                        id="validatepassword"
                        type="password"
                        className='form-control-sm'
                        onChange={(e) => setValidatePassword(e.target.value)}
                    />
                </Form.Group>
                </Row>
                <div className='pt-3 div-justify-middle'>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" type="button" 
                    onClick={handleChangePassword}
                    disabled={loading}>Passwort ändern</button>
                </div>
            </div>
        );
    } else {
        return null
    }
}

export default MemberPassword;